import {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {setIsMobile} from "../redux/slices/layoutSlice";
import {getCurrencyThunk, getUserThunk} from "../redux/thunks/userThunk";
import {resetUser} from "../redux/slices/userSlice";
import {smile} from "../utils/smile";

export const useAppInitialization = () => {

  useEffect(() => {
    smile();
  }, []);

  const dispatch = useDispatch();

  //auth
  const isAuth = useSelector(state => state.auth.isAuth);
  const currency = useSelector(state => state.user.currency);

  useEffect(() => {
    if (isAuth) {
      dispatch(getUserThunk());
    } else {
      dispatch(resetUser());
    }
  }, [isAuth, dispatch]);

  //currency.js
  useEffect(() => {
    if (!currency) {
      dispatch(getCurrencyThunk());
    }
  }, [dispatch, currency]);

  //windowWidth
  useEffect(() => {
    const handleResize = () => {
      dispatch(setIsMobile(window.innerWidth <= 768));
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);
};