import {ENV_CONFIG} from "../config/env";

const SUPPORTED_PROTOCOLS = ['https:', 'http:'];

export const collectImageUrl = ({ id, options }) => {
  let finalId = id;

  try {
    const urlObject = new URL(id);

    if (SUPPORTED_PROTOCOLS.includes(urlObject.protocol)) {
      const pathSegments = urlObject.pathname.split('/');
      finalId = pathSegments[2];
    }

  } catch (error) {}

  const optionsString = options
    ? Object.entries(options)
      .map(([key, value]) => `${key}=${value}`)
      .join(',')
    : '';

  return `https://imagedelivery.net/${ENV_CONFIG.CLOUDFLARE_IMAGES_ID}/${finalId}/${optionsString}`;
};
