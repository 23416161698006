import {axiosInstance} from "../../../config/axios/axiosInstance";
import {CONSTANTS} from "../../../constants/constants";

export const BOOKING_API = {
  getBookingDetails: async ({bookingId, categoryId}) => (
    await axiosInstance.get(`${CONSTANTS.API.API_VERSION.V1}/booking/${bookingId}/${categoryId}`)
  ),
  getBookings: async ({type, filterType}) => (
    await axiosInstance.get(`${CONSTANTS.API.API_VERSION.V1}/booking`, { params: { type, filterType } })
  ),
  addBookingProperty: async ({
      property_id, checkIn, checkOut, members, bookingFor, bookingForName, currency, modeOfPayment, cryptoCurrency, couponCode
    }) => (
    await axiosInstance.post(`${CONSTANTS.API.API_VERSION.V1}/booking/property/booking`, {
      property_id, checkIn, checkOut, members, bookingFor, bookingForName, currency, modeOfPayment, cryptoCurrency, couponCode
    })
  ),
  addBookingCar: async ({
      car_id, pickupDate, dropDate, driver, members, bookingFor, bookingForName, modeOfPayment, currency, couponCode
    }) => (
    await axiosInstance.post(`${CONSTANTS.API.API_VERSION.V1}/booking/car/booking`, {
      car_id, pickupDate, dropDate, driver, members, bookingFor, bookingForName, modeOfPayment, currency, couponCode
    })
  ),
};