import React from 'react';
import { Route, Routes, BrowserRouter} from 'react-router-dom';
import { authRouterConfig } from './configs/authRoutesConfig';
import { publicRoutesConfig } from './configs/publicRoutesConfig';
import {Layout} from "../layout/Layout";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          {Object.entries(publicRoutesConfig).map(([path, Component]) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
          {Object.entries(authRouterConfig).map(([path, Component]) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};