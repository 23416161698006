import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isMobile: window.innerWidth <= 768,
  lastScrollPositionPage: {},
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setIsMobile(state, action) {
      state.isMobile = action.payload;
    },
    setLastScrollPositionPage: (state, action) => {
      const { pageName, scrollPosition } = action.payload;
      state.lastScrollPositionPage[pageName] = scrollPosition;
    },
  }
});

export const {
  setIsMobile,
  setLastScrollPositionPage,
} = layoutSlice.actions;

export default layoutSlice.reducer;
