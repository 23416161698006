import {RENTAL_BOOKING_CONSTANTS} from "./modules/rentalBookingConstants";
import {IMAGES_CONSTANTS} from "./modules/imagesConstants";
import {ALERT_CONSTANTS} from "./modules/alertConstants";
import {API_CONSTANTS} from "./modules/apiConstants";
import {PAGE_LINKS_CONSTANT, PAGE_ROUTES_CONSTANTS} from "./modules/routesConstants";
import {TEXT_CONSTANTS} from "./modules/textConstants";
import {APP_CONFIG_CONSTANT} from "./modules/appConfig";

export const CONSTANTS = {
  API: API_CONSTANTS,
  PAGE_LINKS: PAGE_LINKS_CONSTANT,
  PAGE_ROUTES: PAGE_ROUTES_CONSTANTS,
  IMAGES: IMAGES_CONSTANTS,
  RENTAL_BOOKING: RENTAL_BOOKING_CONSTANTS,
  ALERT: ALERT_CONSTANTS,
  TEXT: TEXT_CONSTANTS,
  APP_CONFIG: APP_CONFIG_CONSTANT,
};