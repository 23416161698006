import styles from "./MobileNavItem.module.css";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {ImageContainer} from "../../ImageContainer/ImageContainer";

const activeColor = '#E89732';
const inactiveColor = '#7c7c7c';
const showDuration = 0.7;

export const MobileNavItem = ({ data, setSelectedIndex, isActive}) => {
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);
  const [positionShow, setPositionShow] = useState({ x: 0, y: 0 });

  const onShow = useCallback((e) => {
    const targetCoords = e?.currentTarget?.getBoundingClientRect();
    const x = e?.clientX - targetCoords?.left;
    const y = e?.clientY - targetCoords?.top;
    setPositionShow({ x, y });
    setIsShow(false);
    setTimeout(() => setIsShow(true), 0);
  }, [setPositionShow, setIsShow]);

  const eventClick = useCallback((e) => {
    setSelectedIndex();
    if (!data?.path) return;
    navigate(data.path);
    onShow(e);
  }, [data?.path, navigate, onShow, setSelectedIndex]);

  const styledShowBlock = {
    borderRadius: '50%',
    animationDuration: showDuration + 's',
    background: '#e8973259',
    top: positionShow.y,
    left: positionShow.x,
  };

  useEffect(() => {
    if (isActive) {
      setSelectedIndex();
    }
  }, [isActive, setSelectedIndex]);

  return (
    <button
      className={styles.item}
      key={data?.title}
      onClick={(e) => eventClick(e)}
    >
      <div
        style={styledShowBlock}
        className={`${styles.showBlock} ${isShow && styles.showBlockOn}`}
      ></div>

      {data?.image !== undefined ?
        (
          <div className={`${styles.image} ${data?.image ? '' : 'loading-fon'} ${isActive ? styles['image-active'] : ''}`}>
            {data?.image && (<ImageContainer id={data?.image}/>)}
          </div>
        )
        :
        data?.icon(isActive, activeColor, inactiveColor)
      }
      <p style={{ color: isActive ? activeColor : inactiveColor }}>{data?.title}</p>
    </button>
  );
}