import {lazy} from "react";
import {CONSTANTS} from "../../constants/constants";

const Home = lazy(() => import("../../pages/Home/Home"));
const NotFoundPage = lazy(() => import("../../pages/NotFoundPage/NotFoundPage"));
const Auth = lazy(() => import("../../pages/Auth/Auth"));

const PropertyList = lazy(() => import("../../pages/searchLists/PropertyList/PropertyList"));
const CarList = lazy(() => import("../../pages/searchLists/CarList/CarList"));
const TourList = lazy(() => import("../../pages/searchLists/TourList/TourList"));

const PropertyDetails = lazy(() => import("../../pages/details/PropertyDetails/PropertyDetails"));
const CarDetails = lazy(() => import("../../pages/details/CarDetails/CarDetails"));
const TourDetails = lazy(() => import("../../pages/details/TourDetails/TourDetails"));

const BookingProperty = lazy(() => import("../../pages/booking/BookingProperty/BookingProperty"));
const BookingCar = lazy(() => import("../../pages/booking/BookingCar/BookingCar"));

export const publicRoutesConfig = {
  [CONSTANTS.PAGE_ROUTES.NOT_FOUND]: NotFoundPage,
  [CONSTANTS.PAGE_ROUTES.AUTH]: Auth,
  [CONSTANTS.PAGE_ROUTES.HOME]: Home,
  [CONSTANTS.PAGE_ROUTES.STAYS]: Home,
  [CONSTANTS.PAGE_ROUTES.CARS]: Home,
  [CONSTANTS.PAGE_ROUTES.TOURS]: Home,
  [CONSTANTS.PAGE_ROUTES.PROPERTY_LIST]: PropertyList,
  [CONSTANTS.PAGE_ROUTES.CAR_LIST]: CarList,
  [CONSTANTS.PAGE_ROUTES.TOUR_LIST]: TourList,
  [CONSTANTS.PAGE_ROUTES.PROPERTY_DETAILS]: PropertyDetails,
  [CONSTANTS.PAGE_ROUTES.CAR_DETAILS]: CarDetails,
  [CONSTANTS.PAGE_ROUTES.TOUR_DETAILS]: TourDetails,
  [CONSTANTS.PAGE_ROUTES.BOOKING_REVIEW_PROPERTY ]: BookingProperty,
  [CONSTANTS.PAGE_ROUTES.BOOKING_REVIEW_CAR]: BookingCar,
};