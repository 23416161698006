import React from "react";
import {Router} from "./routes/Router";
import {useAppInitialization} from "./hooks/useAppInitialization";

import './assets/fonts/Stylesheet.css'; //test
import './styles/global-styles.css';    //test
import './styles/global-variables.css'; //test
import './styles/global-presets.css'; //test

//swiper-global-styles
import 'swiper/css'; //test
import 'swiper/css/bundle'; //test

//react-date-range-global-styles
import './styles/react-date-range/react-date-range-custom-style.css';
import './styles/react-date-range/react-date-range-custom-theme.css';

export const App = () => {

  useAppInitialization();

  return <Router />;
  // return <Router />;
};

// return 1===1 ? <Router /> : <LoadingScreen key="LoadingScreen" />;