import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import {QueryClient, QueryClientProvider} from "react-query";
import {store} from "./redux/store"
import {App} from './App';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <App />
    </Provider>
  </QueryClientProvider>
);