import {axiosInstance} from "../../../config/axios/axiosInstance";
import {CONSTANTS} from "../../../constants/constants";

export const NOTIFICATION_API = {
  getNotifications: async ({userType, limit, offset, sort, sortType}) => (
    await axiosInstance.get(
      `${CONSTANTS.API.API_VERSION.V1}/notification/listing`,
      {params: { userType, limit, offset, sort, sortType }}
    )
  ),
};