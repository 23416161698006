import {CONSTANTS} from "../constants/constants";

const euCountry = [
  "AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI",
  "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU",
  "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE",
];

export const countryCurrencyMap = {
  ...Object.fromEntries(euCountry.map(code => [code, CONSTANTS.API.CURRENCY.EUR])),
  "QA": CONSTANTS.API.CURRENCY.QAR,
  "SA": CONSTANTS.API.CURRENCY.SAR,
  "AE": CONSTANTS.API.CURRENCY.AED,
};