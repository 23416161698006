const branchConfigs = {
  'master': {
    API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
    GOOGLE_MAP_KEY: process.env.REACT_APP_GOOGLE_MAP_KEY,
    CLOUDFLARE_IMAGES_ID: process.env.REACT_APP_CLOUDFLARE_IMAGES_ID,
  },
  'dev': {
    API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
    GOOGLE_MAP_KEY: process.env.REACT_APP_GOOGLE_MAP_KEY,
    CLOUDFLARE_IMAGES_ID: process.env.REACT_APP_CLOUDFLARE_IMAGES_ID
  },
};

const defaultBranch = 'master';

export const ENV_CONFIG = branchConfigs[process.env.BITBUCKET_BRANCH] || branchConfigs[defaultBranch];