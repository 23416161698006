export const API_CONSTANTS = {
  API_VERSION: {
    V1: 'v1',
  },
  STATUS_CODE: {
    SUCCESS: 200,
    UNAUTHORIZED: 211,
  },
  ACCESS_TOKEN: 'accessToken',
  PAYMENT_MODE: {
    CASH: '1',
    CARD: '2',
    CRYPTO: '3',
  },
  BOOKING_FOR: {
    MYSELF: 'MYSELF',
    SOMEONE: 'SOMEONE',
  },
  FILE_UPLOAD_TYPE: {
    MARKET_LEAD: 1,
    WEBSITE_IMAGES: 2,
    PROPERTY: 3,
    CAR: 4,
    TOUR: 5,
    CITY: 6,
    USERS_PROFILE: 7,
    USERS_DOCUMENTS: 8
  },
  PROFILE_BOOKING_FILTERS: {
    ACTIVE: 'active',
    PROPERTY: 'property',
    UPCOMING: 'upcoming',
    PAST: 'past',
    CAR: 'car',
    TOUR: 'tour',
  },
  CURRENCY: {
    EUR: 'EUR',
    QAR: 'QAR',
    SAR: 'SAR',
    AED: 'AED',
    USD: 'USD'
  },
  GUESTS: {
    ADULTS: 'Adults',
    INFANTS: 'Infants',
    CHILDREN: 'Children',
  }
};