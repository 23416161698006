import {useEffect, useState} from "react";
import {debounce} from "lodash";
import {collectImageUrl} from "../../utils/imageUrlParser";

const useResponsiveImage = (imageId, boxRef, initial, options) => {
  const [main, setMain] = useState('');
  const [blur, setBlur] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const updateImageUrl = () => {
      if (boxRef.current && imageId) {
        const boxWidth = boxRef.current.clientWidth;
        const boxHeight = boxRef.current.clientHeight;

        setMain(collectImageUrl({
          id: imageId,
          options: {
            w: boxWidth,
            h: boxHeight,
            // dpr: 1,
            dpr: window.devicePixelRatio,
            fit: 'cover',
            ...(options && {...options}),
          },
        }));

        setBlur(collectImageUrl({
          id: imageId,
          options: {
            w: boxWidth,
            h: boxHeight,
            dpr: 1,
            fit: 'cover',
            blur: 20,
          },
        }));

        setError(
          collectImageUrl({
            id: initial,
            options: {
              w: boxWidth,
              h: boxHeight,
              // dpr: 1,
              dpr: window.devicePixelRatio,
              fit: 'cover',
            },
          })
        );
      }
    };

    updateImageUrl();

    const debouncedUpdateImageUrl = debounce(updateImageUrl, 300);

    window.addEventListener("resize", debouncedUpdateImageUrl);

    return () => {
      window.removeEventListener("resize", debouncedUpdateImageUrl);
    };
  }, [imageId, boxRef, initial, options]);

  return {main, blur, error};
};

export default useResponsiveImage;
