import {useLocation} from "react-router-dom";
import {Fragment, useEffect, useMemo, useState} from "react";
import {MobileNavItem} from "./MobileNavItem/MobileNavItem";
import {useSelector} from "react-redux";
import {CONSTANTS} from "../../constants/constants";
import {SVG_ICON_STATEFUL} from "../common/svg/svg-icon-stateful";
import styles from './MobileNav.module.css';

export const MobileNav = () => {

  const location = useLocation();
  const {pathname} = location;

  const image = useSelector(state => state.user.dataPeople?.image);
  const isAuth = useSelector(state => state.auth.isAuth);

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isLongVisible, setIsLongVisible] = useState(false);

  const categoryData = useMemo(() => ([
    {title: 'Stays', path: CONSTANTS.PAGE_LINKS.STAYS, icon: SVG_ICON_STATEFUL.NAV_STAY},
    {title: 'Cars', path: CONSTANTS.PAGE_LINKS.CARS, icon: SVG_ICON_STATEFUL.NAV_CAR},
    {title: 'Tours', path: CONSTANTS.PAGE_LINKS.TOURS, icon: SVG_ICON_STATEFUL.NAV_TOUR},
    {
      title: isAuth ? 'Account' : 'Sign in',
      path: isAuth ? CONSTANTS.PAGE_LINKS.PROFILE : CONSTANTS.PAGE_LINKS.AUTH,
      icon: SVG_ICON_STATEFUL.LOGIN,
      ...(isAuth && { image: image || null }),
    }
  ]), [isAuth, image]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLongVisible(true);
    }, 250);

    return () => clearTimeout(timer);
  }, []);

  return (
    <nav
      className={styles.wrapper}
      style={{gridTemplateColumns: `repeat(${categoryData.length}, 1fr)`}}
    >
      {pathname !== CONSTANTS.PAGE_ROUTES.HOME && (
        <div
          className={`${styles.long}`}
          style={{
            left: 0,
            transform: `translateX(${selectedIndex * 100}%) scale(${isLongVisible ? '1, 1' : '0, 1'})`,
            width: `${100 / categoryData.length}%`
          }}
        ></div>
      )}
      {categoryData.map((item, index) => {
        return (
          <Fragment key={item?.title}>
            <MobileNavItem
              data={item}
              isActive={pathname === item?.path}
              setSelectedIndex={() => setSelectedIndex(index)}
            />
          </Fragment>
        )
      })}
    </nav>
  )
}