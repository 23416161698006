import {lazy} from "react";
import {withAuth} from "../../hocs/withAuth";
import {CONSTANTS} from "../../constants/constants";

const Profile = lazy(() => import("../../pages/Profile/Profile"));
const BookingDetails = lazy(() => import("../../pages/booking/BookingDetails/BookingDetails"));

export const authRouterConfig = {
  [CONSTANTS.PAGE_ROUTES.PROFILE]: withAuth(Profile),
  [CONSTANTS.PAGE_ROUTES.BOOKING_DETAILS]: withAuth(BookingDetails),
};