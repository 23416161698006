import { useState, useRef, useCallback, useLayoutEffect } from "react";
import { CONSTANTS } from "../../constants/constants";
import useResponsiveImage from "./useResponsiveImage";
import { useInView } from "react-intersection-observer";
import styles from './ImageContainer.module.css';

export const ImageContainer = ({
  id,
  aspectRatio = '1/1',
  isGlare = true,
  isBack = true,
  style = {},
  option,
}) => {

//----------------------------------------------------------------------------------------------------------------------

  const boxRef = useRef(null);
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true });

  const { main, blur, error } = useResponsiveImage(id, boxRef, CONSTANTS.IMAGES.INITIAL, option);

  const [isError, setIsError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isBlurLoaded, setIsBlurLoaded] = useState(false);
  const [isBlurError, setIsBlurError] = useState(false);

  useLayoutEffect(() => {
    if (id) {
      setIsLoaded(false);
      setIsBlurLoaded(false);
      setIsError(false);
      setIsBlurError(false);
    }
  }, [id]);

  useLayoutEffect(() => {
    if (id && inView && isBlurLoaded && !isLoaded && !isBlurError) {
      const img = new Image();
      img.src = main;
      img.onload = () => setIsLoaded(true);
      img.onerror = () => setIsError(true);
    }
  }, [id, inView, main, isBlurLoaded, isLoaded, isBlurError]);

  const setRefs = useCallback(node => {
    boxRef.current = node;
    ref(node);
  }, [ref]);

//----------------------------------------------------------------------------------------------------------------------

  return (
    <div
      className={`${styles.box} ${isBack && !isBlurLoaded ? styles['box-active-back'] : ''}`}
      style={{ aspectRatio, ...style }}
      ref={setRefs}
    >
      {isBack && isGlare && !isLoaded && id && !isBlurLoaded && (
        <div className="loading-fon"></div>
      )}

      {!isBlurError && blur && (
        <img
          className={styles.imgLoading}
          alt={`loading-${blur}`}
          src={blur}
          onLoad={() => setIsBlurLoaded(true)}
          onError={() => setIsBlurError(true)}
        />
      )}

      {id && inView && !isError && !isBlurError && isBlurLoaded && (
        <img
          className={styles.img}
          src={main}
          alt={`${main}`}
        />
      )}

      {((id && inView && isError) || (isBlurError && id)) && (
        <img
          className={styles.img}
          src={error}
          alt={`${error}`}
        />
      )}
    </div>
  );
};
