export const RENTAL_BOOKING_CONSTANTS= {
  RENTAL_TYPE: {
    PROPERTY: 'PROPERTY',
    CAR: 'CAR',
    TOUR: 'TOUR',
  },
  PROFILE_BOOKING_FILTERS: {
    ACTIVE: 'active',
    PROPERTY: 'property',
    UPCOMING: 'upcoming',
    PAST: 'past',
    CAR: 'car',
    TOUR: 'tour',
  },
};
