export const APP_CONFIG_CONSTANT = {
  LAYOUT_CLASS_MOVE: {
    BAR_LISTING: 'bar-listing-block-sticky',
    NAME_HOME_BANNER_FOCUS: 'name-home-banner-focus',
  },
  APP_VERSION: null,
  DEFAULT_LANGUAGE: null,
  SUPPORTED_LANGUAGES: [],
  LIST_QUANTITY_PAGE: {
    PROPERTY: 15,
    CAR: 15,
    TOUR: 15,
  }
};
