export const TEXT_CONSTANTS = {
  APP_NAME: "RentVip",
  BANNER_TITLES: {
    PROPERTY: `Where Opulence\nMeets Comfort`,
    CAR: `Handpicked Luxury\nCar Rentals`,
    TOUR: `Unforgettable Tours\n& Experiences`,
  },
  PROFILE_NAV_BUTTON_TEXTS: {
    BOOKINGS: 'My Bookings',
    NOTIFICATIONS: 'Notifications',
    CHAT: 'Chat Support',
    REVIEWS: 'My Reviews',
  },
};