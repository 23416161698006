import { createSlice } from '@reduxjs/toolkit';
import {localStorageService} from "../../services/localStorageService";

const initialState = {
  currency: localStorageService.get('currency'),
  dataPeople: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrency (state, action) {
      const { currency } = action.payload;
      state.currency = currency;
    },
    setDataPeople (state, action) {
      const { dataPeople } = action.payload;
      state.dataPeople = dataPeople;
    },
    resetUser (state) {
      state.dataPeople = initialState.dataPeople;
    },
  }
});

export const {
  setCurrency,
  setDataPeople,
  resetUser,
} = userSlice.actions;

export default userSlice.reducer;