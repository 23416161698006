import {createAsyncThunk} from "@reduxjs/toolkit";
import {CONSTANTS} from "../../constants/constants";
import {setCurrency, setDataPeople} from "../slices/userSlice";
import {countryCurrencyMap} from "../../utils/currency";
import {localStorageService} from "../../services/localStorageService";
import {API} from "../../services/api/api";

export const getUserThunk = createAsyncThunk(
  'auth/getUser',
  async (_, { dispatch }) => {
    try {
      const res = await API.USER.getUser();

      if (res?.data?.statusCode === CONSTANTS.API.STATUS_CODE.SUCCESS) {
        dispatch(setDataPeople({ dataPeople: res?.data?.data }));
      }
    } catch (error) {
      throw Error(error);
    }
  }
);

export const getCurrencyThunk = createAsyncThunk(
  'auth/getCurrency',
  async (_, { dispatch }) => {
    try {
      const res = await API.LOCATION.getCountry();

      if (res) {
        const currency = countryCurrencyMap[res] || CONSTANTS.API.CURRENCY.USD;
        dispatch(setCurrencyThunk({currency}))
      }
    } catch (error) {
      dispatch(setCurrencyThunk({currency: CONSTANTS.API.CURRENCY.USD}))
      throw Error(error);
    }
  }
);

export const setCurrencyThunk = createAsyncThunk(
  'auth/setCurrency',
  async ({ currency }, { dispatch }) => {
    try {
      localStorageService.set('currency', currency);
      dispatch(setCurrency({currency}))
    } catch (error) {
      throw Error(error);
    }
  }
);