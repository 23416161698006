export const IMAGES_CONSTANTS = {
  INITIAL: '0ba1dffc-9088-4c94-a19d-3160c313f900',
  NATIVE_APP_PROMO: {
    APPLE_STORE: 'f0df5d2a-5b7e-4cc4-5452-ec6022661000',
    GOOGLE_PLAY: '60deef33-2da7-447c-4110-38d5036f9200',
    TEL_LEFT: '66a29548-4f15-4338-899c-8ece0bad7000',
    TEL_RIGHT: 'f8e2c4fc-2be5-4398-8b94-086d7a6e6c00',
  },
  HOME_BANNER_CAROUSEL: {
    PROPERTY: '90a77dd0-f200-4085-4df9-df09da725600',
    CAR: 'f644450c-bb28-4dd9-96c1-81a302e71300',
    TOUR: '5fe32180-a4d4-42af-fb4b-72eee08cc200',
  },
  ABOUT_US_SERVICE: {
    PROPERTY: 'aab5c97d-54b8-4688-55af-e3cb2d746500',
    CAR: '6a8c9dbe-2167-487e-8dd4-44084d505900',
    TOUR: '5fe32180-a4d4-42af-fb4b-72eee08cc200',
    DESIGNER_LEFT: '270ff694-e530-4e37-14c5-018f07493a00',
    DESIGNER_RIGHT: 'd46480f5-4f91-4fed-920d-4fde343e5b00',
  },
};
