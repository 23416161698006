import styles from "./Layout.module.css";
import React, {Suspense, useMemo} from "react";
import {LoadingScreen} from "../components/LoadingScreen/LoadingScreen";
import {Outlet, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {MobileNav} from "../components/MobileNav/MobileNav";

export const Layout = () => {

  const isMobile = useSelector(state => state.layout.isMobile);
  const location = useLocation();
  const { pathname } = location;

  const hiddenRoutes = useMemo(() => (['/', '/stays', '/cars', '/events', '/profile', '/auth']), []);

  return (
    <div className={styles.wrapper}>
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
      </Suspense>
      {hiddenRoutes.includes(pathname) && isMobile && <MobileNav />}
    </div>
  )
};